.title-center {
  text-align: center;
  padding: 0;
}

.image-round {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  img {
    width: 200px;
    border-radius: 50%;
  }
}

.socials {
  display: flex;
  width: 100%;
  justify-content: center;
  

  .contact-link {
    font-size: 40px;
    margin: 16px;
    text-decoration: none;
    color: #25163f;
  }
}

@media (max-width: 600px) {
  .content-image {
    width: 100%;

    .image-round {
      
      img {
        width: 100px;
      }
    }
  }
  

  .title-center {
    font-size: 24px;
  }
}