.project-list {
  display: flex;
  flex-wrap: wrap;
}

.project-card {
  width: 400px;
  margin: 24px;
  // border: 1px solid gray;
  display: flex;
  flex-direction: column;
  position: relative;
 
  .project-main-image {
    width: 100%;
    height: 250px;
  }
  
  button {
    padding: 8px;
    margin-top: 16px;
    background-color: black;
    color: white;
    border: none;
    text-transform: none;
    position: absolute;
    top: 180px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    text-transform: uppercase;
    padding-left: 16px;
    padding-right: 16px;
  }

  &:hover {
    .project-main-image {
      opacity: 0.8;
    }

    button {
      opacity: 0.85;
      display: flex;
    }
  }

  button:focus {
    outline: 2px solid #2d2d2d;
  }

  .project-text {
    // padding: 16px;
    margin-top: 16px;
    line-height: 24px;

    a {
      text-decoration: none;
      color: black;
    }
    
    .project-title {
      margin: 0 0;
      font-size: 18px;
    }

    .project-specs {
        font-size: 16px;
        margin: 0 0;
    }
  }
}