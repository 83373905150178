form {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 0 auto;
  line-height: 24px;

  .form-input {
    margin-bottom: 24px;
    margin-top: 8px;
    padding: 18px;
    font-size: 16px;
  }

  .text-input {
    height: 100px;
    margin-bottom: 24px;
    margin-top: 8px;
    padding: 18px;
    font-size: 16px;
  }

  label {
    opacity: 0.7;
  }

}

@media (max-width: 600px) {
  form {
    width: 300px;

    .form-input, .text-input {
      border: 1px solid black;
      opacity: 0.7;
    }
  }
}