.content-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 0;
  
  .content-image {
    width: 50%;
    margin-top: 20px;

    .about-photo {
      width: 100%;
    }
  }

  .content-text {
    width: 50%;
    margin-top: -15px;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 16px;
    line-height: 24px;
    font-size: 18px;
    align-self: center;

    
  }
}

.feat {
  padding: 40px;
  
  h2 {
    font-size: 32px;
    margin-bottom: 32px;
  }

  .feat-list {
    list-style: none;
    padding: 0;

    .feat-item {
      
      .link-icon {
        margin-right: 8px;
        color: #25163f;
        font-size: 24px;
        padding-top: 2px;
        margin-bottom: 24px;
      }

      a {
        text-decoration: none;
        color: black;
        opacity: 0.8;
        font-size: 18px;
      }
    }
  }
}

.spacer {
  margin-top: 150px;
}

.skills-container {
  display: flex;
  margin-top: 0;
  width: 100%;
  
  .left-list {
    width: 50%;

    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      list-style: none;
      border: 1px solid  #25163f;
      border-radius: 32px;
      padding: 16px;
      text-align: center;
      margin: 8px;
    }

    .skill-title {
      font-size: 32px;
      padding: 24px;
      margin-left: 16px;
    }
  }

  .right-list {
    width: 50%;

    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      list-style: none;
      padding: 0;
      border: 1px solid  #25163f;
      border-radius: 32px;
      padding: 16px;
      text-align: center;
      margin: 8px;
    }

    .skill-title {
      font-size: 32px;
      padding: 24px;
      margin-left: 16px;
    }
  }
}

@media (max-width: 600px) {
  .content-container {
    flex-wrap: wrap;
    .content-image {
      width: 100%;
    }
  
    .content-text {
      width: 100%;
      padding: 24px;
      margin-left: 24px;

      h1 {
        font-size: 32px;
      }
    }
  }

  .skills-container {
    flex-wrap: wrap;

    .right-list, .left-list {
    width: 100%;
    }
  }
}
