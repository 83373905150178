html, body {
  font-family: 'Lato', sans-serif;
  height: 100%;
  margin-bottom: 70px;
}

h1 {
  font-family: 'Jost', sans-serif;
}

.page-container {
  min-height: 100vh;
  padding-bottom: 20px;
  margin-bottom: 24px;
}

.page-title {
  font-size: 32px;
  width: 100%;
  padding-left: 24px;
}
