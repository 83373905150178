.footer {
  //position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  margin-top: 70px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: black;

  .footer-links {
    display: flex;
    justify-content: flex-end;
    padding: 24px;

    .f-link {
      text-decoration: none;
      color: white;
      padding-right: 24px;
      font-size: 24px;
    }
  }
}