.content-container {
  height: 100%;
  //padding-bottom: 300px;
  margin-top: 200px;

  .project-title {
    margin-left: 24px;
    font-size: 40px;
   }
   
   .project-images {
    width: 100%;
    height: 500px;
    
    .project-slide {
      width: 100%;
      margin: 0 auto;
    }
   
     .project-slide-slim {
      margin: 0 auto;
      width: 280px;
      height: 450px;
    }
   }
   
  .content-text {
     
    .project-description {
      margin-left: 24px;
      margin-bottom: 24px;
    }
    
    .tech-list {
      margin-top: 32px;
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    
      .tech-list-item {
        display: inline;
        font-size: 14px;
        opacity: 0.8;
        background-color: #25163f;
        color: white;
        padding-left: 10px;
        padding-right: 8px;
        padding-top: 4px;
        padding-bottom: 4px;
        margin: 2px;
        border-radius: 24px;
        text-align: center;
      }
    }
     
    .project-links {
      margin-left: 18px;
      margin-top: 16px;
      padding-top: 24px;
      padding-bottom: 24px;
      
      span {
        opacity: 0.7;
        align-self: center;
      }
      .buttons-list {
        padding: 0;
        list-style-type: none;
        display: flex;
    
        .list-item {
          margin-left: 8px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .tech-list {
    display: flex;
    flex-wrap: wrap;
  }

  .buttons-list {
    flex-wrap: wrap;
    margin-top: 16px;
  }

  .content-text {
  
    .project-description {
  
      h2 {
        font-size: 24px;
      }
    }
  }
}
